<template>
    <div id="container">
        <div id="contents">
            <div class="contents_header">
                <div class="TitleBox">
                    <p class="tit_b">NCS 직무 자가진단 평가</p>
                    <p class="tit_s"></p>
                </div>
            </div>
            <div class="contents_body">
                <div class="ncs-wrap">
                    <div class="my-ncs">
                        <div class="my-ncs-tit">NCS 직무 자가진단 평가</div>
                        <div class="ncs-section">
                            <div class="ncs-clas">
                                <div class="clas-tit">대분류</div>
                                <div class="clas-select">정보통신</div>
                            </div>
                            <div class="ncs-clas">
                                <div class="clas-tit-job">중분류</div>
                                <div class="clas-select-job">
                                    <div class="select_wrap">
                                        <select disabled> 
                                            <option selected>{{clsfNm.midClassNm}}</option> 
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="ncs-clas">
                                <div class="clas-tit-job">소분류</div>
                                <div class="clas-select-job">
                                    <div class="select_wrap">
                                        <select disabled> 
                                            <option selected>{{clsfNm.minorClassNm}}</option> 
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="ncs-clas">
                                <div class="clas-tit-job">직무분야</div>
                                <div class="clas-select-job">
                                    <div class="select_wrap">
                                        <select disabled> 
                                            <option selected>{{clsfNm.detailClassNm}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>	
                        </div>
                        <div class="ncs-info">
                            <div class="ncs-meg">	
                                <span class="ico_info"></span> NCS Level 항목에 표시된 Level은 본인이 등록한 기술신고서에 의해 자동으로 산출된 Level이므로,각 Level에 따른 능력단위명에 해당하는 자가진단을 하여야 합니다.<br>
                                <span class="ico_none"></span> 모든 능력단위명을 진단완료하여야 최종적으로 본인의 기술등급이 등록됩니다.
                            </div>
                        </div>
                    </div>
                    
                    <div class="my-ncs">
                        <div class="my-ncs-tit"><span>{{clsfNm.detailClassNm}} > {{clsfNm.abilUnitNm}}</span> 자가진단 작성</div>
                        <div class="my-ncs-table">
                            <table class="ncs-type">
                                <colgroup>
                                        <col width="180">
                                        <col width="*">
                                        <col width="60">
                                        <col width="60">
                                        <col width="60">
                                        <col width="60">
                                        <col width="60">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>진단영역</th>
                                            <th>진단문항</th>
                                            <th>매우<br>미<span @click="allClickRdo('1')">흡</span></th>	
                                            <th>미<span @click="allClickRdo('2')">흡</span></th>
                                            <th>보<span @click="allClickRdo('3')">통</span></th>	
                                            <th>우<span @click="allClickRdo('4')">수</span></th>
                                            <th>매우<br>우<span @click="allClickRdo('5')">수</span></th>
                                        </tr>
                                        <tr v-for="(row, index) in qst" :key="row.selfDiagQeusCd">
                                            <td class="ncsrow" v-if="row.mergeChkCnt == row.mergeCnt" :rowspan="row.mergeCnt">{{row.abilUnitElemNm}}</td>
                                            <td class="ncs">{{row.qeusCont}}</td>
                                            <td class="vote"><input type="radio" :name="'rdo' + index" :id="row.selfDiagQeusSeq + '_1'" value="1" v-model="row.chk" @change="chkRdo(row)" :disabled="clsfNm.ncsGradeCd && row.chk != '1'"><label :for="row.selfDiagQeusSeq + '_1'"><span></span></label></td>
                                            <td class="vote"><input type="radio" :name="'rdo' + index" :id="row.selfDiagQeusSeq + '_2'" value="2" v-model="row.chk" @change="chkRdo(row)" :disabled="clsfNm.ncsGradeCd && row.chk != '2'"><label :for="row.selfDiagQeusSeq + '_2'"><span></span></label></td>
                                            <td class="vote"><input type="radio" :name="'rdo' + index" :id="row.selfDiagQeusSeq + '_3'" value="3" v-model="row.chk" @change="chkRdo(row)" :disabled="clsfNm.ncsGradeCd && row.chk != '3'"><label :for="row.selfDiagQeusSeq + '_3'"><span></span></label></td>
                                            <td class="vote"><input type="radio" :name="'rdo' + index" :id="row.selfDiagQeusSeq + '_4'" value="4" v-model="row.chk" @change="chkRdo(row)" :disabled="clsfNm.ncsGradeCd && row.chk != '4'"><label :for="row.selfDiagQeusSeq + '_4'"><span></span></label></td>
                                            <td class="vote"><input type="radio" :name="'rdo' + index" :id="row.selfDiagQeusSeq + '_5'" value="5" v-model="row.chk" @change="chkRdo(row)" :disabled="clsfNm.ncsGradeCd && row.chk != '5'"><label :for="row.selfDiagQeusSeq + '_5'"><span></span></label></td>
                                        </tr>
                                    </tbody>	
                            </table>
                        </div>
                    </div>
                    
                    <div class="my-ncs">
                        <div class="my-ncs-tit"><span>{{clsfNm.detailClassNm}} > {{clsfNm.abilUnitNm}}</span> 진단결과</div>
                        <div class="my-ncs-table">
                            <table class="ncs-type">
                                <colgroup>
                                        <col width="*">
                                        <col width="100">
                                        <col width="100">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>진단영역</th>
                                            <th>문항수</th>
                                            <th>점수</th>	
                                        </tr>
                                        <tr v-for="row in avg" :key="row.elemCd" :class="row.elemNm == '합계' ? 'tot' : ''">
                                            <td>{{row.elemNm}}</td>
                                            <td class="num">{{row.queCnt}}</td>
                                            <td class="num">{{row.sum}}</td>	
                                        </tr>
                                    </tbody>	
                            </table>
                        </div>
                    </div>
                    
                    <div class="my-ncs-btn">
                        <div class="save" @click="btnSubmit()" v-if="!clsfNm.ncsGradeCd">진단결과 저장</div>
                        <div class="cancel" @click="btnBack()">
                            <span v-if="!clsfNm.ncsGradeCd">취소</span>
                            <span v-else>이전</span>
                        </div>
                    </div>
                </div>
            </div><!--//contents_body-->
        </div><!--//contents-->
    </div><!--//container-->
</template>

<script>
export default {
    data() {
        return {
            rspData : {
                unitCd : this.$route.query.unitCd,
                mberTechGradeFinalSeq : this.$route.query.mberTechGradeFinalSeq
            },
            options : {
                headers : {'Authorization' : 'Bearer ' + sessionStorage.getItem('access_token')}
            },
            clsfNm : {},
            avg : [],
            qst : []
        }
    },
    mounted() {
        this.openNcsDiagDetail();
    },
    methods : {
        openNcsDiagDetail() {
            var param = {
                loginSeq : sessionStorage.getItem('seq'),
                unitCd : this.rspData.unitCd,
                mberTechGradeFinalSeq : this.rspData.mberTechGradeFinalSeq
            };
            
            this.$axios.post('/api/res/openNcsDiagDetail', param, this.options)
                .then(res => {
                    // console.log(res.data);
                    
                    this.clsfNm = res.data.data.clsfNm;
                    this.avg = res.data.data.avg;
                    this.qst = res.data.data.qst;

                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 
                    alert(err);
                    //alert(err.response.data.error_description);
                });
        },

        chkRdo(q) {
            if(q.cuDiv == '') {
                q.cuDiv = 'U';
            }
            
            var eleCd =  q.abilUnitElemCd;
            var eleSum = 0; // 각 영역 점수 합
            var totSum = 0; // 총 영역 점수 합
            
            // 점수 합
            for(var i in this.qst) {
                var sltEleCd = this.qst[i].abilUnitElemCd;
                var score = Number(this.qst[i].chk == null ? 0 : this.qst[i].chk);
                
                if(sltEleCd == eleCd) {
                    eleSum += score;
                }
                
                totSum += score;
            }
            
            // 점수/문항 수
            for(var x in this.avg) {
                var avgEleCd = this.avg[x].elemCd;
                
                if(avgEleCd == eleCd) {
                    var eleQst = Number(this.avg[x].queCnt);
                    
                    this.avg[x].sum = eleSum;
                    this.avg[x].avg = (eleSum / eleQst).toFixed(2);
                }
            }
            
            // 합계
            var tot = this.avg[this.avg.length - 1];
            var totQst = Number(tot.queCnt);
            
            tot.sum = totSum;
            tot.avg = (totSum / totQst).toFixed(2);
        },

        allClickRdo(val) {
            if(this.qst[0].ncsLevelCd == null) {
                for(var i in this.qst) {
                    this.qst[i].chk = val;
                    
                    this.chkRdo(this.qst[i]);
                }
            }
        },

        btnSubmit() {
            //var chkAry = new Array();
            var notChkCnt = 0;

            for(var i in this.qst) {
                if(!this.qst[i].chk) {
                    //chkAry.push(Number(i) + 1);
                    notChkCnt++;
                }
            }

            // for(var i = 0; i <= this.qst.length - 1; i++) {
            //     //var chk = $('input:radio[name=rdo'+i+']').is(':checked');

            //     console.log("chk : " + chk);
                
            //     if(!chk) {
            //         chkAry.push(i + 1);
            //     }
            // }
            
            if(notChkCnt == 0) {
                var sendData = new Object();
                var paramAry = new Array();
                
                for(var z in this.qst) {
                    var param = new Object();
                    
                    var cuDiv = this.qst[z].cuDiv;
                    
                    if(cuDiv != '') {
                        var qstSeq = this.qst[z].selfDiagQeusSeq;
                        var resSeq = this.qst[z].selfDiagResultSeq;
                        var score  = this.qst[z].chk;
                        
                        param.qstSeq = qstSeq;
                        param.score = score;
                        param.resSeq = resSeq;
                        param.cuDiv = cuDiv;
                        
                        paramAry.push(param);
                    }
                }
                
                // console.log('paramAry', paramAry);
                
                if(paramAry.length != 0) {
                    sendData.data = paramAry;
                    sendData.detailCd = this.clsfNm.detailClassCd;
                    sendData.mberTechGradeFinalSeq = this.clsfNm.mberTechGradeFinalSeq;
                    sendData.loginSeq = sessionStorage.getItem('seq');
                    
                    // console.log('reqeust sendData :', sendData);
                    
                    // pnAjax($scope, {
                    //     url : '/res/istNcsSelfDiagUnit.do',
                    //     data : sendData,
                    //     success : function(rs) {
                    //         if(rs.resultCode == "000"){
                    //             var backParam = {};
                    //             backParam.moveAction = 'back';
                    //             backParam.ncsDetailClassCd = this.clsfNm.detailClasCd;
                                
                    //             //pnMove('/res/RES003M01.do', backParam);
                    //         }
                    //     }
                    // });

                    this.$axios.post('/api/res/istNcsSelfDiagUnit', sendData, this.options)
                        .then(res => {
                            // console.log(res.data);
                            
                            if(res.data.resultCode == "000"){
                                // var backParam = {};
                                // backParam.moveAction = 'back';
                                // backParam.ncsDetailClassCd = this.clsfNm.detailClasCd;
                                
                                // pnMove('/res/RES003M01.do', backParam);

                                this.$router.push('/res/RES003M01?moveAction=back&ncsDetailClassCd=' + this.clsfNm.detailClasCd);
                            }

                        })
                        .catch(err => {
                            // console.log('============================='); 
                            // console.log(err);
                            // console.log(err.response);
                            // console.log('============================='); 
                            alert(err);
                            //alert(err.response.data.error_description);
                        });
                }else {
                    var backParam = {};
                    backParam.moveAction = 'back';
                    backParam.ncsDetailClassCd = this.clsfNm.detailClassCd;
                    
                    //pnMove('/res/RES003M01.do', backParam);
                }
            }else {
                alert("자가진단이 완료되지 않았습니다.");
                return false;
            }
        }
    }
}
</script>